import React from 'react';

function Kontakt() {
  return (
    <div className='kontakt' id='kontakt'>
        <div className='kontakt_content'>
            <h2>Kontakt</h2>
            <h3 className='kontakt_text'>
                Für Fragen und mehr Informationen können Sie uns gerne anrufen oder schreiben. Wir freuen uns auf Ihre Nachricht!
            </h3>
        </div>
    </div>
  );
}

export default Kontakt;