import React from 'react';
import din3834 from '../media/3834.jpg';
import din1090 from '../media/1090.jpg';

function Subheader() {
  return (
    <div className='subheader' id='subheader'>
        <div className='subheader__content'>
          <h2>Über Uns</h2>
            <h3 style={{color:'black'}} className='subheader__text'>
            Als freundliches Familienunternehmen mit dem Sitz in Bremen beschäftigen wir uns seit 15 Jahren mit dem Thema: Edelstahl rund um´s Haus.
            <br/>
            <br/>
             Exklusive Edelstahlkonstruktion nach Maß ist unsere Spezialität. Wo Standard an die Grenzen stößt, fangen wir für Sie mit Lösung an. Sonderwünsche sind unser Tagesgeschäft. Sei es eine Terrassenüberdachung, ein Geländer oder doch etwas Ausgefallenes - Wir sind für Sie da.
            <br/>
            <br/>
            Wir sind ein zertifizierter Schweißfachbetrieb nach DIN EN ISO 3834 und DIN EN 1090.
            </h3>
            <div className='zerti-wrapper'>
              <img className='zerti-img' src={din3834}/>
              <img className='zerti-img' src={din1090}/>
            </div>
        </div>
    </div>
  );
}

export default Subheader;