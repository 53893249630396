import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from './logo';

class Nav extends React.Component {

  render () {
    let startLink;
    let referenceLink;
    let leistungenLink;
    let weLink;
    
    if (this.props.site === 1) {
        startLink = <h4><AnchorLink href='#start'>Start</AnchorLink></h4>;
        referenceLink = <h4><AnchorLink href='#subheader'>Über Uns</AnchorLink></h4>;
        leistungenLink = <h4><AnchorLink href='#gallery_parent'>Referenzen</AnchorLink></h4>;
        weLink = <h4><AnchorLink href='#kontakt'>Kontakt</AnchorLink></h4>;

    } else if (this.props.site === 2) {
        startLink = <h4><a href='/'>Start</a></h4>;
        referenceLink = <h4><a href='/#subheader'>Über Uns</a></h4>;
        leistungenLink = <h4><a href='/#gallery_parent'>Referenzen</a></h4>;
        weLink = <h4><a href='/#kontakt'>Kontakt</a></h4>;
    }
    

    return (
      <nav>
        <Logo />
        <div className='nav__links'>
          {startLink}
          {referenceLink}
          {leistungenLink}
          {weLink}
        </div>  
      </nav>
    );
  }
}

export default Nav;
