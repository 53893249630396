import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Nav from './components/Nav';
import Header from './components/Header';
import Subheader from './components/Subheader';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Impressum from './components/Impressum';
import SmallHeader from './components/SmallHeader';
import BurgerMenu from './components/BurgerMenu';
import Line from './components/Line';
import Kontakt from './components/Kontakt';
import Map from './components/Map';
import Kontaktformular from './components/Kontaktformular';

import GlobalStyle from './GlobalStyles';
import './App.css';

import foto from './media/jpg/brett-jordan-LKiB35E8iGA-unsplash.jpg';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos : 0
    };
  }

  render() {
    return (
      <Router>
      <div className='app'>
        <GlobalStyle />
          <Switch>
            <Route path='/' exact>
              <Nav site={1}/>
              <BurgerMenu site={1}/>          
              <Header />
              <Subheader />
              <Line />
              <Gallery />
              <Line />
              <Kontakt />
              <Map />
              <Kontaktformular />
            </Route>
            <Route path='/impressum' exact>
              <Nav site={2}/>
              <BurgerMenu site={2}/>      
              <SmallHeader header='Impressum' image={foto} />
              <Impressum />
            </Route>
          </Switch>
        <Footer />
      </div>
    </Router>
    );
  }
}

export default App;