import { createGlobalStyle } from 'styled-components';

var primaryColor = 'rgb(255, 255, 255)';
var secondaryColor = 'rgb(255, 255, 255)';
var tertiaryColor = 'rgb(255, 255, 255)';
var white = 'rgb(0,0,0)';
var navColor = 'rgb(244, 244, 244)';

const GlobalStyle = createGlobalStyle`
    
    body {
        background: ${primaryColor};
    }

    nav {
        background-color: ${navColor};
    }

    h1 h2 h3 h4 h5 {
        color: ${white};
    }

    p {
        color: ${white};
    }

    .header_main_text {
        color: ${white};
    }

    .header_sub_text {
        color: ${white};
    }

    .header_slogan {
        color: ${white};
    }

    .subheader {
        background-color: ${secondaryColor};
    }

    .references {
        background-color: ${primaryColor};
    }

    .reference_main_text {
        color: ${white};
    }

    .reference_see_more {
        color: ${white};
    }

    .team {
        background-color: ${secondaryColor};
    }

    .team_background {
        color: ${white};
    }

    .infos {
        color: ${white};
    }

    .footer {
        background-color: ${navColor};
    }

    .footer-phone {
        color: ${white};
    }

    .footer-mail {
        color: ${white};
    }

    .footer-adress {
        color: ${white};
    }

    .skills {
        background-color: ${tertiaryColor};
    }

    .skills__small {
        background-color: ${tertiaryColor};
    }

    h1, h2, h3, h4, h5 {
        color: ${white};
    }

    .gallery {
        background-color: ${primaryColor};
    }

    .small_header_main_text {
        color: ${white};
    }

    .small_header_sub_text {
        color: ${white};
    }
`;

export default GlobalStyle;