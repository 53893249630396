import React from 'react';

function Impressum() {

    return (
        <div className='impressum'>
            <div className='impressum__content'>
                <div className='impressum__text'>
                    <div id='impressum' className='offset'/>
                    <h2>
                        Impressum
                    </h2>
                    <h3>
                        <b>Angaben gemäß § 5 TMG</b><br/>
                        <br/>
                        Sparwasser Exklusive Edelstahl-Konstruktion nach Maß<br/>
                        Oppenheimer Str. 13<br/>
                        28307 Bremen<br/>
                        <br/>
                        Handelsregister: *<br/>
                        Registergericht: *<br/>
                        <br/>
                        Vertreten durch:<br/>
                        Waldemar Sparwasser<br/>
                        <br/>
                        <b>Kontakt</b><br/>
                        Telefon: <a href='tel:01708638013'>0170 / 86 38 013</a><br/>
                        E-Mail: <a href="mailto:info@sparwasser-edelstahl.de">info@sparwasser-edelstahl.de</a><br/>
                        <br/>
                        <b>Umsatzsteuer-ID</b><br/>
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br/>
                        *<br/>
                        <br/>
                    </h3>

                <div id="datenschutz">

                    <h2>Datenschutzerklärung</h2>
                    <h2>Allgemeiner Hinweis und Pflichtinformationen</h2>
                    <h3>Benennung der verantwortlichen Stelle</h3>
                    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                    <p><span id="s3-t-firma">Sparwasser Exklusive Edelstahl-Konstruktion nach Maß</span>
                    <br/>
                    <span id="s3-t-ansprechpartner">Waldemar Sparwasser</span>
                    <br/>
                    <span id="s3-t-strasse">Oppenheimer Str. 13</span>
                    <br/>
                    <span id="s3-t-plz">28307</span> <span id="s3-t-ort">Bremen</span></p>
                    <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
                    <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                    <p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
                    <h3>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h3>
                    <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
                    <h3>Recht auf Datenübertragbarkeit</h3>
                    <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
                    <h3>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h3>
                    <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>
                    <h3>SSL- bzw. TLS-Verschlüsselung</h3>
                    <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>

                    <h2>Server-Log-Dateien</h2>
                    <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                    <ul>
                    <li>
                    <p>Besuchte Seite auf unserer Domain</p>
                    </li>
                    <li>
                    <p>Datum und Uhrzeit der Serveranfrage</p>
                    </li>
                    <li>
                    <p>Browsertyp und Browserversion</p>
                    </li>
                    <li>
                    <p>Verwendetes Betriebssystem</p>
                    </li>
                    <li>
                    <p>Referrer URL</p>
                    </li>
                    <li>
                    <p>Hostname des zugreifenden Rechners</p>
                    </li>
                    <li>
                    <p>IP-Adresse</p>
                    </li>
                    </ul>
                    <p>Diese Daten sind für uns technisch erforderlich, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten. (Rechtsgrundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. f DSGVO) Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt.</p>

                    <h2>Kontaktformular</h2>
                    <p>Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</p>
                    <p>Je nach Art der Anfrage ist die Rechtsgrundlage für diese Verarbeitung Art. 6 Abs. 1 lit. b DSGVO für Anfragen, die Sie selbst im Rahmen einer vorvertraglichen Maßnahme stellen oder Art. 6 Abs. 1 S. 1 lit. f DSGVO, wenn Ihre Anfrage sonstiger Art ist. Sollten personenbezogene Daten abgefragt werden, die wir nicht für die Erfüllung eines Vertrages oder zur Wahrung berechtigter Interessen benötigen, erfolgt die Übermittlung an uns auf Basis einer von Ihnen abgegebenen Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO.t vom Widerruf unberührt.</p>
                    <p>Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</p>

                    <h2>Google Maps</h2>
                    <h3>Art und Umfang der Verarbeitung</h3>
                    <p>Wir verwenden zur Erstellung von Anfahrtsbeschreibungen den Kartendienst Google Maps. Google Maps ist ein Dienst der Google Ireland Limited, welcher auf unserer Website eine Karte darstellt. Wenn Sie auf diese Inhalte unserer Website zugreifen, stellen Sie eine Verbindung zu Servern der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland her, wobei Ihre IP-Adresse und ggf. Browserdaten wie Ihr User-Agent übermittelt werden. Diese Daten werden ausschließlich zu den oben genannten Zwecken und zur Aufrechterhaltung der Sicherheit und Funktionalität von Google Maps verarbeitet.</p>
                    <h3>Zweck und Rechtsgrundlage</h3>
                    <p>Der Einsatz von Google Maps erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und §25 Abs. 1 TDDDG. Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. Die Datenübermittlung in die USA erfolgt nach Art. 45 Abs. 1 DSGVO auf Grundlage des Angemessenheitsbeschluss der Europäischen Kommission. Die beteiligten US-Unternehmen und/oder deren US-Unterauftragnehmer sind nach dem EU-U.S. Data Privacy Framework (EU-U.S. DPF) zertifiziert.</p>
                    <h3>Speicherdauer</h3>
                    <p>Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Google Ireland Limited bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Google Maps: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>

                    <h2>Font Awesome</h2>
                    <h3>Art und Umfang der Verarbeitung</h3>
                    <p>Wir verwenden zur ordnungsgemäßen Bereitstellung der Inhalte unserer Website Font Awesome des Anbieters Fonticons, Inc..</p>
                    <h3>Zweck und Rechtsgrundlage</h3>
                    <p>Der Einsatz von Font Awesome erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TDDDG.</p>
                    <p>Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. Dies sind – sofern nicht anders angegeben – Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. Eine Kopie dieser Standardvertragsklauseln können Sie unter <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE</a> einsehen.</p>
                    <p>Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.</p>
                    <h3>Speicherdauer</h3>
                    <p>Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Fonticons, Inc. bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Font Awesome CDN: <a href="https://cdn.fontawesome.com/privacy">https://cdn.fontawesome.com/privacy</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Impressum;