import React from 'react';
import logo from '../media/logo_small.svg';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

function Logo() {

    const { scrollYProgress } = useViewportScroll();
    const anim = useTransform(scrollYProgress, [0, 0.02], [0, 1]);   

    return (
        <motion.a
            style={{ opacity: anim, 'transitionDuration': '0.5s' }}
            href="/"
        >
            <img src={logo} alt='logo' className='nav__logo'/>
        </motion.a> 
    );
}

export default Logo;