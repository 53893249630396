import React from 'react';
import foto2klein from '../media/jpg/brett-jordan-LKiB35E8iGA-unsplash.jpg';
import logo from '../media/logo.svg';

import { motion } from 'framer-motion';

function Header() {
  return (
    <div className='header' id='start'>
      
      <motion.div
        animate={{ y: -10, opacity: 1 }}
        transition={{ duration: 2.5 }}
        initial={{ y: -40, opacity : 0 }}
        className='header_text'
      >
        <img src={logo} className='header_logo'  alt='logo' />
        {/* <h1 className='header_main_text'>
          SPARWASSER
        </h1>
        <h2 className='header_sub_text' style={{fontSize:'100px'}}>
          EDELSTAHL
        </h2> */}
      </motion.div>
      <img src={foto2klein} alt='headerImage' className='header_img'/>
    </div>
  );
}

export default Header;