import React from 'react';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

function GalleryComponent(props) {
    return (
        <div className='galleryComponent'>
            <AutoplaySlider
                play={true}
                cancelOnInteraction={true}
                interval={2000}
                bullets={false}
                organicArrows={true}
            >
                {props.images.map(function (image) {
                    return <div data-src={image} key={image.default} />
                })}
            </AutoplaySlider>
            <h2>{props.name}</h2>
        </div>
    );
}

export default GalleryComponent;