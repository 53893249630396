import React from 'react';

import map from '../media/jpg/map.png';

function Map() {
  return (
    <div className="map" id='map'>
      <a href={'https://goo.gl/maps/dQtryucXPBJpFdwZA'}>
        <img className='mapImage' src={map} alt={'map'}/>
      </a>
    </div>
  );
}

export default Map;