import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { init, sendForm } from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
init('user_bVdzTGiPTJyAMSwn6rNex');

export default function Kontaktformular() {
  
  const { register, handleSubmit } = useForm();
  const [isClicked, setClicked] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isSuccessful, setSuccesful] = useState(false);

  const onSubmit = (data) => {
    setClicked(true);
    setFailed(false);
    setSuccesful(false);
    sendForm('sparwasser-kontakt', 'template_p8dftyt', '#contact-form')
      .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        setSuccesful(true);
        setClicked(false);
      }, function(error) {
        console.log('FAILED...', error);
        setFailed(true);
        setClicked(false);
      });
  }

  return (
    <div className='form_content' id='#form'>
      <form id='contact-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='contact-form-top'>
          <input
            id='name_input'
            type='text'
            name='user_name' 
            {...register('Name', { required: true})} 
            placeholder='Name'/>
          <br/>
          <input
            id='email_input'
            type='email'
            name='user_email' 
            {...register('Email', { required: true})}
            placeholder='Email'/>
          <br/>
          <br/>
        </div>
        
        <textarea
          id='message_input'
          name='message' 
          {...register('Message', { required: true})}/>
        <br/>
        <input
          className={isClicked ? 'hidden' : 'form_button'}
          type='submit' 
          value='Abschicken' />
        
      </form>

      <Loader
        className={isClicked ? 'loader' : 'hidden'}
        type="TailSpin"
        color="#252589"
        height={50}
        width={50}
        timeout={0} 
      />

      <FontAwesomeIcon 
        icon={faCheckCircle} 
        className={isSuccessful ? 'form_icon' : 'hidden'}
      />
      <FontAwesomeIcon
        icon={faTimesCircle} 
        className={isFailed ? 'form_icon' : 'hidden'}
      />
    </div>
  );
}