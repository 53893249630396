import React from 'react';

import GalleryComponent from './GalleryComponent';

function importAll(req) {
    return req.keys().map(req);
}

const terassenueberdachungImages = importAll(require.context('../media/jpg/terassenueberdachung', false, /\.(png|jpe?g|svg)$/));
const terassenImages = importAll(require.context('../media/jpg/terassen', false, /\.(png|jpe?g|svg)$/));
const vordaecherImages = importAll(require.context('../media/jpg/vordaecher', false, /\.(png|jpe?g|svg)$/));
const zaeuneImages = importAll(require.context('../media/jpg/zaeune', false, /\.(png|jpe?g|svg)$/));
const gelaenderImages = importAll(require.context('../media/jpg/gelaender', false, /\.(png|jpe?g|svg)$/));
const dekorationImages = importAll(require.context('../media/jpg/dekoration', false, /\.(png|jpe?g|svg)$/));

export default class Gallery extends React.Component {
  render () {
    return (
      <div className='gallery_parent' id='gallery_parent'>
        <h2 className='gallery_headline'>
            Referenzen
        </h2>
        <div className='gallery'>
          <GalleryComponent name='Terrassenüberdachung' images={terassenueberdachungImages} play={true} />
          <GalleryComponent name='Terrassen' images={terassenImages} play={false} />
          <GalleryComponent name='Vordächer' images={vordaecherImages} play={false} />
          <GalleryComponent name='Zäune' images={zaeuneImages} play={false} />
          <GalleryComponent name='Geländer' images={gelaenderImages} play={false} />
          <GalleryComponent name='Dekoration' images={dekorationImages} play={false} />
        </div>
      </div>
    );
  }
}