import React from 'react';

function SmallHeader(props) {

    return (
        <div className='small_header' id='start'>
            <h1 className='small_header_main_text'>
                {props.header}
            </h1>
            <img src={props.image} alt='small_header_image'/>
        </div>
    );
}

export default SmallHeader;